import dayjs from 'dayjs'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { Facebook, Linkedin, Rss, Twitter } from 'react-feather'
import { TwitterTimelineEmbed } from 'react-twitter-embed'
// import Layout from '../components/Layout'
import BlogLayout from '../components/BlogLayout'
import Pagination from '../components/Pagination'
import PostList from '../components/PostList'
import SearchBar from '../components/SearchBar'

function SocialLinksWidget({ rssFeed = null, socialMedia }) {
  return (
    <div className="horizontal-list is-flex">
      {rssFeed && (
        <a aria-label="Blog rss feed" href={rssFeed} style={{ height: 28 }}>
          <div className="with-bg-orange with-br-1em">
            <Rss className="has-text-white" />
          </div>
        </a>
      )}
      <a
        aria-label="DFS twitter link"
        href={socialMedia.twitter}
        target="_blank"
        rel="noopener noreferrer"
        style={{ height: 28 }}
      >
        <div className="with-bg-twitter with-br-1em">
          <Twitter className="has-text-white" />
        </div>
      </a>
      <a
        aria-label="DFS facebook link"
        href={socialMedia.facebook}
        target="_blank"
        rel="noopener noreferrer"
        style={{ height: 28 }}
      >
        <div className="with-bg-facebook with-br-1em">
          <Facebook className="has-text-white" />
        </div>
      </a>
      <a
        aria-label="DFS linkedin link"
        href={socialMedia.linkedin}
        target="_blank"
        rel="noopener noreferrer"
        style={{ height: 28 }}
      >
        <div className="with-bg-linkedin with-br-1em">
          <Linkedin className="has-text-white" />
        </div>
      </a>
    </div>
  )
}

function BlogPage({ data: blogData, pageContext }) {
  const { edges: posts } = blogData.allWordpressPost
  const { events } = blogData.allWordpressAcfOptions.edges[0].node.options
  const {
    socialMedia,
  } = blogData.allWordpressAcfOptions.edges[0].node.options.footer
  const { featuredMedia } = pageContext

  const filteredEvents = events.filter(event => event.name !== 'filler')
  const filteredPosts = posts.filter(({ node: post }) => post.slug !== 'filler')

  return (
    <BlogLayout
      hero={{
        title: 'Blog',
        subtitle: 'Resources and thoughts from the DFS team',
      }}
      seo={{ pageTitle: 'Blog' }}
      featuredMedia={featuredMedia}
      lightText
    >
      <div role="main">
        <section className="section">
          <div className="container">
            <div className="content">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <div className="col-span-2">
                  {filteredEvents.length > 0 && (
                    <div className="mb-32">
                      <h2>Upcoming Events</h2>
                      <div className="wp-block-columns has-4-columns">
                        {filteredEvents.length ? (
                          filteredEvents.map(event =>
                            event.link ? (
                              <a
                                href={event.link}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                <div className="card--default">
                                  <div className="card-content">
                                    <h4 className="mb-4">{event.name}</h4>
                                    <p className="mb-4">
                                      {dayjs(event.from).format('MMMM D, YYYY')}{' '}
                                      - {dayjs(event.to).format('MMMM D, YYYY')}
                                    </p>
                                    <p className="semibold">{event.location}</p>
                                  </div>
                                </div>
                              </a>
                            ) : (
                              <div className="card">
                                <div className="card-content">
                                  <h4 className="mb-4">{event.name}</h4>
                                  <p className="mb-4">
                                    {dayjs(event.from).format('MMMM D, YYYY')} -{' '}
                                    {dayjs(event.to).format('MMMM D, YYYY')}
                                  </p>
                                  <p className="semibold">{event.location}</p>
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <h6>No events found.</h6>
                        )}
                      </div>
                    </div>
                  )}
                  {filteredPosts.length > 0 ? (
                    <>
                      <PostList posts={filteredPosts} numOfColumns={1} />
                      <div className="pt-16">
                        <Pagination pageContext={pageContext} />
                      </div>
                    </>
                  ) : (
                    <div className="column">
                      <p>No posts yet</p>
                    </div>
                  )}
                </div>
                <div className="column is-hidden-mobile">
                  <SearchBar />
                  <div className="has-border br-5 mtb-16">
                    <TwitterTimelineEmbed
                      sourceType="profile"
                      screenName="DataFusionUSA"
                      noFooter
                      options={{ height: 700 }}
                    />
                  </div>
                  <div className="has-border br-5 my-4 p-4">
                    <SocialLinksWidget
                      socialMedia={socialMedia}
                      rssFeed="/blog.xml"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </BlogLayout>
  )
}

BlogPage.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export default BlogPage

export const pageQuery = graphql`
  fragment PostFields on wordpress__POST {
    id
    title
    author {
      name
      slug
      avatar_urls {
        wordpress_96
      }
    }
    meta: fields {
      readingTime {
        text
      }
    }
    categories {
      name
      slug
    }
    tags {
      name
      slug
    }
    excerpt
    date(formatString: "MMM D, YYYY")
    dateTime: date(formatString: "YYYY-MM-DD")
    featured_media {
      alt_text
      localFile {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    acf {
      isFeatured
    }
    content
    slug
  }
  query IndexQuery($limit: Int!, $skip: Int!) {
    allWordpressPost(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostFields
        }
      }
    }
    allWordpressAcfOptions {
      edges {
        node {
          options {
            events {
              name
              from
              to
              location
              link
            }
            footer {
              socialMedia {
                twitter
                linkedin
                facebook
              }
            }
          }
        }
      }
    }
  }
`
