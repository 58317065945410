import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Search } from 'react-feather'

const DEFAULT_SEARCH_INDICES = [
  { slug: 'posts', name: 'Posts' },
  { slug: 'categories', name: 'Categories' },
  { slug: 'tags', name: 'Tags' },
]

function SearchBar({
  indexDropdown = false,
  indexes = DEFAULT_SEARCH_INDICES,
}) {
  const [searchQuery, setSearchQuery] = useState('')
  const [currentIndex, setCurrentIndex] = useState(indexes[0].slug)

  const onSearchChange = e => {
    setSearchQuery(e.target.value)
  }

  const onIndexChange = e => {
    setCurrentIndex(e.target.value)
  }

  const onSearch = e => {
    e.preventDefault()
    if (searchQuery.length > 0)
      navigate(`/search/?query=${searchQuery}&index=${currentIndex}`)
  }

  return (
    <form onSubmit={onSearch}>
      <div className="columns">
        <div className="column">
          <div className="control is-marginless has-icons-left blog-search-input">
            <input
              className="input"
              type="search"
              placeholder="Search..."
              value={searchQuery}
              aria-label="Search input bar"
              onChange={onSearchChange}
            />
            <span className="icon is-medium is-left">
              <Search />
            </span>
          </div>
        </div>
        {indexDropdown && (
          <div className="column is-narrow">
            <div className="select is-rounded">
              <select
                value={currentIndex}
                onChange={onIndexChange}
                aria-label="Search index selector"
              >
                {indexes.map(index => (
                  <option key={index.slug} value={index.slug}>
                    {index.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </div>
    </form>
  )
}

SearchBar.propTypes = {
  /**
   * Allows the user to change which index they are searching.
   */
  indexDropdown: PropTypes.bool,

  /**
   * Specify which indexes the user can choose from.
   */
  indexes: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
}

export default SearchBar
