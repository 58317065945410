import PropTypes from 'prop-types'
import React from 'react'
import CookieAgreement from './CookieAgreement'
import Footer from './Footer'
import Hero from './Hero'
import Navbar from './Navbar'
import SEO from './SEO'

const BlogLayout = ({ children, hero, seo, lightText, featuredMedia }) => {
  return (
    <React.Fragment>
      <SEO {...seo} />
      <Navbar lightText={lightText} />
      <Hero {...hero} featuredMedia={featuredMedia} />
      {children}
      <Footer />
      <CookieAgreement />
    </React.Fragment>
  )
}

BlogLayout.propTypes = {
  className: PropTypes.string,
  header: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }),
  seo: PropTypes.shape({
    isBlogPost: PropTypes.bool,
    isLandingPage: PropTypes.bool,
    postData: PropTypes.shape({
      acf: PropTypes.object,
      author: PropTypes.shape({
        name: PropTypes.string,
        slug: PropTypes.string,
        path: PropTypes.string,
      }),
      categories: PropTypes.array,
      content: PropTypes.string,
      date: PropTypes.string,
      id: PropTypes.string,
      slug: PropTypes.string,
      tags: PropTypes.array,
      title: PropTypes.string,
    }),
    postImage: PropTypes.string,
    pageTitle: PropTypes.string,
    pageDescription: PropTypes.string,
  }),
}

export default BlogLayout
